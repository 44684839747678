import React,{Fragment,useState,useEffect} from 'react';
import classes from './InstallOrder.module.css'
import CompletePaid from './CompletePaid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faSave, faCheck, faStar} from '@fortawesome/free-solid-svg-icons'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";
import isLoggedIn from '../UserLogin/UserLogin';
import {useCookies} from 'react-cookie';
import Input from '../UI/Input';
import axios from 'axios';

  
const pencil = <FontAwesomeIcon icon={faPencilAlt} />
const save = <FontAwesomeIcon icon={faSave} />
const check = <FontAwesomeIcon icon={faCheck} />
const star = <FontAwesomeIcon icon={faStar}/>

const InstallOrder = (props) =>{
  const [ready,setReady] = useState(0);

  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [statusText,setStatusText] = useState("");
  const [installDate, setInstallDate] = useState('');
  const [dayDiff, setDayDiff] = useState(0);
  const [completeModal, setCompleteModal] = useState('');
  const [editClick,setEditClick] = useState(true);
  const [tailorName, setTailorName] = useState(props.tailorName);
  
  useEffect(() => {
    
    setIsLoaded(true);
  }, []);


  
  useEffect(() => {
    setInstallDate(props.install_at);
  },[isLoaded]);
  


  const handleEditClick = () =>{
    setEditClick(false);
  }
  const handleSaveClick = () =>{
    const date = new Date(installDate);
      const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/order/update',{
        key: cookies.Token,
        order_id: props.id,
        install_at:installDate
        },
        config)
      .then(function (response) {
        console.log(response);
        console.log(installDate);
        props.onSave({id:props.id,fullName:props.fullName, date:installDate})
        setInstallDate(`${response.data.order.install_at.split('T')[0]} ${response.data.order.install_at.split('T')[1]}`);
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
        console.log(error);
      });
    setEditClick(true);
  }
  
  const handleCheckClick = () =>{
    setCompleteModal(<CompletePaid id={props.id} yesClick={handleCheckYes} noClick={handleCheckNo}/>)
      
  }
  const handleCheckYes = () =>{
    let detailed = JSON.parse(JSON.parse(props.order.payment).detailed);
    if (detailed.length !== undefined){
    let paymentSum = 0;
    for (let detail of detailed){
      paymentSum = Number(detail.amount) + paymentSum;
    }
      let currDate = new Date();
      detailed.push({
        type:"pliromi",
        amount:JSON.parse(props.order.payment).synolo-paymentSum, 
        how:'metrita',
        date: currDate
        })
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/order/update',{
        key: cookies.Token,
        order_id: props.id,
        status:'completed',
        payment:JSON.stringify({...JSON.parse(props.order.payment), detailed:JSON.stringify(detailed)})
        },
        config)
      .then(function (response) {
        console.log(response);
        setCompleteModal('')
        props.completed({id:props.id,fullName:props.fullName, date:installDate});
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
        console.log(error);
      });
    }
  }
  const handleCheckNo = () =>{
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
      };
      axios.post('https://api.orders.e-interior.gr/api/order/update',{
        key: cookies.Token,
        order_id: props.id,
        status:'ypoloipo'
        },
        config)
      .then(function (response) {
        console.log(response);
        setCompleteModal('')
        props.completed({id:props.id,fullName:props.fullName, date:installDate});
      })
      .catch(function (error) {
        if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
        console.log(error);
      });
  }

  const handleChange = (event) =>{
    setInstallDate(event.target.value);
  }
  useEffect(()=>{
    const createdDate = new Date(props.created_at);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - createdDate.getTime();
    setDayDiff(Math.floor(timeDiff / (1000 * 60 * 60 * 24)));
  },[isLoaded])

useEffect(()=>{
  switch(props.status) {
    case "not_submitted_yet":
      setStatusText("Δημιουργήθηκε");
      break;
    case "submitted":
      setStatusText("Καταχωρήθηκε");
      break;
    case "preparing":
      setStatusText("Ετοιμάζεται");
      break;
    case "modifying":
      setStatusText("Ρέλι/Μεταποίηση");
      break;
    case "ready":
      setStatusText("Όλα έτοιμα");
      break;
    case "communicated":
      setStatusText("Ειδοποιήθηκε");
      break;
    case "completed":
      setStatusText("Ολοκληρωμένη");
      break;
    case "canceled":
      setStatusText("Ακυρωμένη");
      break;
    case "repairing":
      setStatusText("Επισκευάζεται");
      break;
    default:
      setStatusText(props.status);
      break;
  }
  },[isLoaded,props.status])


const viewClick = () =>{
    props.modalShow({modal:true, id:props.id,fullName:props.fullName, status:props.status, scrollPos:window.scrollY});
  }


    return(

      <Fragment>
      {completeModal}
        <tr  className={`${classes.orders} ${dayDiff>=7&&dayDiff<10 ? classes.seven:''} ${dayDiff>=10 ? classes.ten:''}`}>
          <td onClick={viewClick} >{props.id}</td>
          <td onClick={viewClick}>{props.city}</td>
          <td onClick={viewClick}>{props.vip === 1 &&(<span>{star}</span>)}{props.fullName}</td>
          <td onClick={viewClick}>{statusText}</td>
          <td onClick={viewClick}>{props.created_at.split('T')[0]}</td>
          {editClick && <td onClick={viewClick}>{installDate}</td>}
          {!editClick &&
            <td>
            <input onChange={handleChange} value={installDate} type='datetime-local'>
            </input></td>}
          <td>{tailorName?tailorName:'-'}</td>
          {editClick &&<td title="Επεξεργασία" onClick={handleEditClick}>{pencil}</td>}
          {!editClick && <td title="Επεξεργασία" onClick={handleSaveClick}>{save}</td>}
          {props.status==="ready"||props.status==="communicated" ?<td onClick={handleCheckClick} title="Ολοκλήρωση">{check}</td>:<td></td>}
        </tr>

      </Fragment>)
}
export default InstallOrder;
