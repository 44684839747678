import React, {useState,useEffect, Fragment} from 'react';
import Card from '../UI/Card';
import InstallOrder from './InstallOrder'
import Button from '../UI/Button'
import classes from './Placements.module.css'
import ViewOrderInProgress from '../OrdersInProgress/ViewOrderInProgress'
import { Navigate, Route, Routes, useNavigate,Outlet, Link  } from "react-router-dom";

import {useCookies} from 'react-cookie';
import axios from 'axios';
import oauth  from 'axios-oauth-client';


const Placements = () =>{
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['user']);
  const [authorizationCookie, setAuthorizationCookie, removeAuthorizationCookie] = useCookies(['authorization'])
  const [orders,setOrders] = useState([]);
  const [orderDetailed, setOrderDetailed] = useState('')
  const [clients,setClients] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [editClick,setEditClick] = useState(true);
  const [orderChange, setOrderChange] = useState('');

  const [statusChange, setStatusChange] = useState(false);
  const [modalShow,setModalShow] = useState(false);
  const [currentId,setCurrentId] = useState('');
  const [currentFullName,setCurrentFullName] = useState('');
  const [currentStatus, setCurrentStatus] = useState('')

  const [scrollPos, setScrollPos] = useState(0);

  const [authorizationCode, setAuthorizationCode] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  
  useEffect(()=>{
    setIsLoaded(!isLoaded);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.orders.e-interior.gr/api/verify',
      headers: { 
        'Accept': 'application/json', 
        'Authorization': 'Bearer '+cookies.Token, 
      },
    };

    axios.request(config)
    .then((response) => {
      console.log(response.data)
      if (response.data.message!=="Success"){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      };
    })
    .catch((error) => {
      removeCookies('Name', { path: '/' });
      removeCookies('Token', { path: '/' });
      navigate("/user-login");
    });

  },[])

  useEffect(()=>{
    if (isLoaded){const urlParams = new URLSearchParams(window.location.search);
        
        if(urlParams.get('code')){
          setAuthorizationCode(urlParams.get('code'))
          setAuthorizationCookie('authorization',urlParams.get('code'))
        }
        if(authorizationCookie.refresh!==undefined){
          setRefreshToken(authorizationCookie.refresh)
        }
      }
  },[isLoaded])

  useEffect(()=>{
    if(authorizationCookie.authorization!==undefined){
    setAuthorizationCode(authorizationCookie.authorization)
    }
    if(authorizationCookie.authorization!==undefined&&authorizationCookie.refresh===undefined){
      const clientId = '221135142836-a30djv1erbmbob58m14a4dl61cp09rkk.apps.googleusercontent.com';
      const clientSecret = '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'; // Replace with your actual access token
      const redirectUri = 'https://orders.e-interior.gr/installations';
      
      axios.post('https://oauth2.googleapis.com/token', {
        code: authorizationCookie.authorization,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: 'authorization_code',
      })
      .then((refreshResponse) =>{
        console.log('Refresh_token', refreshResponse.data.access_token)
        setAuthorizationCookie('refresh',refreshResponse.data.access_token)
        setRefreshToken(refreshResponse.data.access_token)
      })
      .catch((error) =>{
        console.error('Error exchanging authorization code:', error);
      })

    }
  },[authorizationCookie])
  

  useEffect(() => {
  if (!cookies.Token) {
    navigate("/user-login");
  }});

    useEffect(() => {
      setIsLoaded(true);
  }, []);

    useEffect(()=>{
      document.querySelector("html").scrollTo(0, scrollPos);
    }, [scrollPos])

    useEffect(() => {
    const config = {
      headers: { 'Authorization': 'Bearer '+cookies.Token}
    };
    //get all orders 
    axios.post('https://api.orders.e-interior.gr/api/orders',{
      key: cookies.Token,
      installFilter: true
    },
      config
    )
    .then(function (response) {
      console.log(response.data);
      setOrders(response.data);

    })
    .catch(function (error) {
      if (error.response.data.message==="Unauthenticated."){
        removeCookies('Name', { path: '/' });
        removeCookies('Token', { path: '/' });
        navigate("/user-login");
      }
      console.log(error);
    });

    //get all clients
    axios.post('https://api.orders.e-interior.gr/api/clients',{
      key: cookies.Token},
      config
    )
    .then(function (response) {

      setClients(response.data);

    })
    .catch(function (error) {
      console.log(error);
    });



  },[isLoaded, orderChange, statusChange])

  const handleCompleted = (e) =>{
    setOrderChange(e.id)
    const authUrl = 'https://oauth2.googleapis.com/token';
    const clientId = '221135142836-a30djv1erbmbob58m14a4dl61cp09rkk.apps.googleusercontent.com';
    const clientSecret = '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'; // Replace with your actual access token
    const redirectUri = 'https://orders.e-interior.gr/installations';
    const taskListId = 'MDM4MDc0MjU2ODczMjA1ODgyMTI6MDow'; 
    const apiUrl = `https://www.googleapis.com/tasks/v1/users/@me/lists/${taskListId}`;
    
    const taskName='Τοποθέτηση #'+e.id+' '+e.fullName
    const taskStatus='completed'

    const taskUrl = `https://tasks.googleapis.com/tasks/v1/lists/${taskListId}/tasks`;

    axios.get(taskUrl, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }).then((response) =>{
      const existingTasks = response.data.items 
      for (const task of existingTasks) {
      if (task.title === taskName) {
        // Update the status of the existing task
        task.status = taskStatus;
        const updateUrl = `${taskUrl}/${task.id}`;
        axios.patch(updateUrl, task, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        console.log('Task updated:', task);
        break;
      }
    }    
    });
  }

  const onCloseHandler = () =>{
    setModalShow(false);
    setCurrentId('');
    setCurrentFullName('');
    setStatusChange(!statusChange);
  }

  const handleModalShow = (e) =>{
    setModalShow(e.modal);
    setCurrentId(e.id);
    setCurrentFullName(e.fullName);
    setCurrentStatus(e.status);
    setScrollPos(e.scrollPos);
  }

  const handleConnectClick = (e) =>{
    const clientId = '221135142836-a30djv1erbmbob58m14a4dl61cp09rkk.apps.googleusercontent.com';
    const clientSecret = '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'; // Replace with your actual access token
    const redirectUri = 'https://orders.e-interior.gr/installations';
    const taskListId = 'MDM4MDc0MjU2ODczMjA1ODgyMTI6MDow'; // Replace with the ID of the task list you want to add the task to


    if (!authorizationCode){
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=https://www.googleapis.com/auth/tasks`;
      window.location.href = authUrl;
    }

  }

  const handleSave = (e) =>{
    console.log('Code is: '+authorizationCode)
    const authUrl = 'https://oauth2.googleapis.com/token';
    const clientId = '221135142836-a30djv1erbmbob58m14a4dl61cp09rkk.apps.googleusercontent.com';
    const clientSecret = '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'; // Replace with your actual access token
    const redirectUri = 'https://orders.e-interior.gr/installations';
    const taskListId = 'MDM4MDc0MjU2ODczMjA1ODgyMTI6MDow'; 
    const apiUrl = `https://www.googleapis.com/tasks/v1/users/@me/lists/${taskListId}`;
    
    const taskName=e.date.split('T')[1]+' '+'Τοποθέτηση #'+e.id+' '+e.fullName
    const taskDate=e.date+':00.000Z'

    const taskUrl = `https://tasks.googleapis.com/tasks/v1/lists/${taskListId}/tasks`;
    let taskExists = false;

    axios.get(taskUrl, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    }).then((response) =>{
      const existingTasks = response.data.items 
      for (const task of existingTasks) {
      if (task.title === taskName) {
        // Update the due date of the existing task
        task.due = taskDate;
        taskExists = true;
        const updateUrl = `${taskUrl}/${task.id}`;
        axios.patch(updateUrl, task, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        console.log('Task updated:', task);
        break;
      }
    } 
      if (!taskExists) {
      const taskPayload = {
          title: taskName,
          due: taskDate
        };

        axios.post(taskUrl, taskPayload, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        })
        .then((taskResponse) =>{
          console.log('Task created:', taskResponse.data);
        })
        .catch((error) =>{
          console.log('Error while posting task', error);
        });
        }    
    });
    
    
    
  }
  return(
    <Fragment>
    <Card className={classes.ordersCard}>
    <h2>Τοποθετήσεις</h2>
    
    
    {!modalShow &&
      <Fragment>
      <div className={classes.googleTasks}>
        {authorizationCode ?
        <p>Συνδεμένο με Google Tasks</p>:
        <Button onClick={handleConnectClick}>Σύνδεση με Google Tasks</Button>
        }
      </div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Πόλη</th>
            <th>Πελάτης</th>
            <th>Κατάσταση</th>
            <th>Ημ. Παραγγελίας</th>
            <th>Ημ. Τοποθέτησης</th>
            <th>Ραφείο</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
      { orders.map(({id,client_id,fullName,city, vip, hasInstallation, status, created_at, install_at, ordered_at, products},index)=>{
        if (status!=="not_submitted_yet"&&status!=="canceled"&&status!=="proxeiro"){
          const tailorName = Array.from(
            new Set(
                products
                    .filter(product => product.last_status[0]?.status === 'rafeio') // Filter products by status
                    .map(product => product.last_status[0].note) // Extract the notes
            )
          ).join(', ');
          console.log(tailorName);
        return(
          <InstallOrder
          onSave={handleSave}
          modalShow={handleModalShow}
          completed={handleCompleted}
          id={id}
          key={id}
          fullName={fullName}
          city={city}
          vip={vip}
          status={status}
          created_at={created_at}
          install_at={install_at ? install_at: ''}
          order={orders[index]}
          tailorName={tailorName}
          />)}
        })}
      </tbody>
    </table>
    
    </Fragment>}
    </Card>
    {modalShow && <ViewOrderInProgress id={currentId} fullName={currentFullName} onClose={onCloseHandler} />}
    </Fragment>
  )
}
export default Placements;
